import React, { Component } from "react";
import "./Gallery.css";
import Carousel from "../../node_modules/react-bootstrap/Carousel";
import { API_URL } from "../index.js";

class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      galleryItems: [],
    };
  }

  async componentDidMount() {
    this.populateGalleryItems();
  }

  expand(evt) {
    evt.target.classList.toggle("galleryItemExpanded");
  }

  renderGalleryItems() {
    if (!this.state.galleryItems) return;
    return this.state.galleryItems.map((item, index) => {
      return (
        <Carousel.Item key={index}>
          <img
            className="d-block w-100 gallery-item"
            src={item.file}
            alt={`Gallery ${index}`}
            onClick={(evt) => this.expand(evt)}
          />
        </Carousel.Item>
      );
    });
  }

  async populateGalleryItems() {
    const response = await this.getGalleryItems();
    this.setState({ galleryItems: response });
  }

  getGalleryItems() {
    const url = `${API_URL}/api/gallery/`;
    return new Promise((resolve, reject) => {
      fetch(url).then((response) => {
        resolve(response.json());
      });
    });
  }

  render() {
    return (
      <div id="gallery">
        <Carousel>{this.renderGalleryItems()}</Carousel>;
      </div>
    );
  }
}

export default Gallery;
