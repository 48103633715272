import { fetchPost, fetchGet } from "./Fetch.js"

const postAuthRequest = (payload) => fetchPost(payload, '/api/auth/')
const getUserPermissionsByToken = (token) => fetchGet(`/api/user_permissions/${token}/`)

const getAuthToken = () => {
  const token = localStorage.getItem("authernticated_user_token");
  return token;
};

export { postAuthRequest, getAuthToken, getUserPermissionsByToken };
