import { API_URL } from "./index.js";
import { getCsrfToken } from "./Cookies.js"

const fetchPost = (payload, urlArg) => {
    const url = `${API_URL}${urlArg}`;
    return new Promise((resolve, reject) => {
      fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'X-CSRFToken': getCsrfToken()
        },
        body: JSON.stringify(payload),
      })
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          reject(alert(`Unexpected error occurred during POST request processing`));
        });
    });
  };


  const fetchGet = (urlArg) => {
    const url = `${API_URL}${urlArg}`;
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => {
          resolve(response.json());
        })
        .catch((error) => {
          reject(alert(`Unexpected error occurred during GET request processing`));
        });
    });
  };

  export { fetchPost, fetchGet };