import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { API_URL } from "../..";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function PaymentResponse() {
  const { paymentId } = useParams();
  const [state, setState] = useState([]);

  useEffect(() => {
    if (!state || state.length !== 0) return;
    fetch(`${API_URL}/api/payment_status/${paymentId}/`)
      .then((res) => res.json())
      .then((data) => setState(data[0]));
  });

  let navigate = useNavigate();
  const routeChangeReturnToPurchase = () => navigate(`/product_purchase/${state.product}`);
  const routeChangeReturnToHome = () => navigate(`/`);

  if (state && state.payment_status === 1) {
    return (
      <div className="main-content">
        <div className="payment-status">
          <h3>Paldies, ka esat izvēlējušies studiju "Nemierīga telpa"!</h3>
          <p>
          Jūsu maksājums ir apstiprināts un pasūtījums ir nodots apstradei. 
          </p>

          <p>
          Rēķins tika nosūtīts uz jūsu norādīto e-pasta adresi ({state.email}). 
          </p>

          <p>Jūsu pasūtījuma identifikācijas numurs: {state.unique_id}</p>

          <Button type="submit" onClick={routeChangeReturnToHome}>
            Atpakaļ uz sākumu
          </Button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="main-content">
        <div className="payment-status">
          <h3 className>Ak nē! Apmaksa nav izdevusies.</h3>
          <Button type="submit" onClick={routeChangeReturnToPurchase}>
            Atpakaļ uz pasutijumu
          </Button>
        </div>
      </div>
    );
  }
}
