import React from "react";
import Alert from "react-bootstrap/Alert";

export default class SchedulerAlert extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: false,
      variant: this.props.variant,
      text: this.props.text
    };
  }


  render() {
    if (!this.props.show || this.state.hide) return(<></>)

    return (
      <div className="shedulerInstructionDiv">
        <Alert
          key="shedulerInstruction"
          variant={this.state.variant}
          className="shedulerInstruction"
          dismissible
          onClose={() => {this.setState({hide: true})}}
        >
          <h5>
            {this.state.text}
            </h5>
        </Alert>
      </div>
    );
  }
}
