import React, { Component } from "react";
import "./Main.css";
import TextSection from "./components/TextSection";
import Gallery from "./components/Gallery";
import Contacts from "./components/Contacts";
import {API_URL} from './index.js'
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      strings: {},
    };
  }

  async componentWillMount() {
    this.populateStrings()
  }

  async populateStrings() {
    const response = await this.getStrings();
    let obj = {};
    response.forEach(e => obj[e.title] = e)
    this.setState({ strings: obj });
  }

  getStrings() {
    const url = `${API_URL}/api/strings/`;
    return new Promise((resolve, reject) => {
      fetch(url).then((response) => {
        resolve(response.json());
      });
    });
  }


  render() {
    return (
      <main>

      <div className="main-content">
      <div className="master-header">
        <span className="header-text">
          Nāc un izmēģini jaunās mēģinājumu telpas !
        </span>
      </div>
      <TextSection id='about_us' strings={this.state.strings.about_us}/>
      <Gallery/>
      <TextSection strings={this.state.strings.times} />
      <div className="master-header">
        <span className="header-text">
          Nāc un izmēģini jaunās mēģinājumu telpas !
        </span>
      </div>
      <TextSection strings={this.state.strings.services} />
      <Contacts />
      </div>
    </main>
    );
  }
}

export default Main;
