import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import ProductCatalogue from "./components/product-catalogue/ProductCatalogue";
import ProductPurchase from "./components/product-catalogue/ProductPurchase";
import Main from "./Main";
import TermsAndConditions from "./components/TermsAndConditions"
import ShedulerPage from "./components/SchedulerPage"
import Login from "./components/Login"
import PaymentResponse from "./components/product-catalogue/PaymentResponse";
export const API_URL = (process.env.NODE_ENV === 'development') ? 'http://127.0.0.1:8000' : '' 

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Main />} />
      <Route path="/main" element={<Main />} />
      <Route path="/product_catalogue" element={<ProductCatalogue />} />
      <Route path="/product_purchase/:id" element={<ProductPurchase />} />
      <Route path="/terms_and_conditions" element={<TermsAndConditions />} />
      <Route path="/scheduler" element={<ShedulerPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/payment_response/:paymentId" element={<PaymentResponse />} />
    </Routes>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);


document.body.style.webkitTransform =  'scale(1)';
document.body.style.msTransform =   'scale(100)';
document.body.style.transform = 'scale(1)';

