import React from "react";

export default function TextSection({ strings, id }) {
  return renderTextSection(strings, id);
}

function renderTextSection(strings, id) {
  if (!strings) return <></>;
  return (
    <div className="container" id={id}>
      <div dangerouslySetInnerHTML={{ __html: strings.lv }}></div>
    </div>
  );
}
