import React from "react";
import "./Contacts.css";
export default function Contacts() {
  return (
    <>
      <div className="row contact-row">
        <div className="col-lg-12 text-center">
          <h5 className="text-uppercase section-heading contacts-heading">
            Kontakti
          </h5>
        </div>
      </div>
      <div className="row text-center" id="contacts">
        <div className="col">
          <span className="fa-stack fa-2x">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-telephone-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M2.267.98a1.636 1.636 0 0 1 2.448.152l1.681 2.162c.309.396.418.913.296 1.4l-.513 2.053a.636.636 0 0 0 .167.604L8.65 9.654a.636.636 0 0 0 .604.167l2.052-.513a1.636 1.636 0 0 1 1.401.296l2.162 1.681c.777.604.849 1.753.153 2.448l-.97.97c-.693.693-1.73.998-2.697.658a17.47 17.47 0 0 1-6.571-4.144A17.47 17.47 0 0 1 .639 4.646c-.34-.967-.035-2.004.658-2.698l.97-.969z"
              />
            </svg>
          </span>
          <h6 className="section-heading">
            <strong>Klientu serviss:</strong>
          </h6>
          <p className="text-muted">20450050</p>
        </div>

        <div className="col">
          <span className="fa-stack fa-2x">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-geo-alt-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
              />
            </svg>
          </span>
          <h6 className="section-heading">
            <strong>Studijas adrese:</strong>
          </h6>
          <a
            href="https://goo.gl/maps/bMvvpvWfiaFQg57S8"
            target="_blank"
            rel="noreferrer"
          >
            <p className="text-muted">Tērbatas iela 76, Rīga</p>
          </a>
        </div>

        <div className="col">
          <span className="fa-stack fa-2x">
            <svg
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              className="bi bi-envelope-fill"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555zM0 4.697v7.104l5.803-3.558L0 4.697zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757zm3.436-.586L16 11.801V4.697l-5.803 3.546z"
              />
            </svg>
          </span>
          <h6 className="section-heading">
            <strong>Studijas e-pasts:</strong>
          </h6>
          <p className="text-muted">info@nt.studio</p>
        </div>
      </div>
    </>
  );
}
