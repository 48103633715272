import React, { Component } from "react";
import TextSection from "./TextSection";
import {API_URL} from '../index.js'

export default class TermsAndConditions extends Component {
    constructor(props) {
      super(props);
      this.state = {
        strings: {},
      };
    }

    async componentWillMount() {
      this.populateStrings()
    }
  
    async populateStrings() {
      const response = await this.getStrings();
      let obj = {};
      response.forEach(e => obj[e.title] = e)
      this.setState({ strings: obj });
    }
  
    getStrings() {
      const url = `${API_URL}/api/strings/`;
      return new Promise((resolve, reject) => {
        fetch(url).then((response) => {
          resolve(response.json());
        });
      });
    }
  
  
    render() {
      return (
        <div className="main-content">
            <TextSection strings={this.state.strings.terms_and_conditions}/>
        </div>
      );
    }
  }
    