import React, { Component } from "react";
import "./ProductDetails.css";
import Button from "../../../node_modules/react-bootstrap/Button";
import Carousel from "../../../node_modules/react-bootstrap/Carousel";
import { Link } from "react-router-dom";

const carouselIndicator = false;
const carouselControls = false;

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      images: "",
      price: null,
      currency: null,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.productData) return;
    return {
      id: props.productData.uuid,
      title: props.productData.title,
      images: props.productData.product_images,
      price: props.productData.price,
      currency: props.productData.currency,
      description: props.productData.description,
    }
  }

  renderProductImages() {
    if (
      !this.state.images ||
      this.state.images.len === 0 ||
      this.state.images === ""
    )
      return;
    return this.state.images.map((item, index) => {
      return (
        <Carousel.Item key={index}>
          <img
            className="d-block gallery-item"
            src={item.file}
            alt={`Gallery ${index}`}
          />
        </Carousel.Item>
      );
    });
  }

  render() {
    return (
      <>
        <div className="product-details">
          {this.state.images.length > 0 && (
            <Carousel
              indicators={carouselIndicator}
              controls={carouselControls}
            >
              {this.renderProductImages()}
            </Carousel>
          )}
          <div className="product-title">
            <h3>{this.state.title}</h3>
          </div>
          <div className="product-price">
            Cena: {this.state.price} {this.state.currency}
            <Link to={{ pathname: `/product_purchase/${this.state.id}` }}>
              <Button className="buy-btn" variant="primary">
                Pirkt
              </Button>
            </Link>
          </div>
          <div className="product-description">{this.state.description}</div>
        </div>
      </>
    );
  }
}

export default ProductDetails;
