import React, { Component } from "react";
import "./ProductCatalogue.css";
import ProductDetails from "./ProductDetails";
import ProductCategories from "./ProductCategories";
import { API_URL } from "../..";

class ProductCatalogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      productCategories: [],
      categoryFilter: null,
    };
  }

  async componentDidMount() {
    this.populateProducts();
    this.populateProductCategories();
  }

  async populateProducts() {
    const response = await this.getProducts();
    this.setState({ products: response });
  }

  getProducts() {
    const url = `${API_URL}/api/products/`;
    return new Promise((resolve, reject) => {
      fetch(url).then((response) => {
        resolve(response.json());
      });
    });
  }

  renderProductList() {
    let productsRednered = 0;
    const redneredOutput = this.state.products.map((product) => {
      if (
        !product.category ||
        (this.state.categoryFilter &&
          product.category.trim() !== this.state.categoryFilter.trim())
      )
        return;

      productsRednered += 1;
      return (
        <div key={product.pk}>
          <ProductDetails productData={product} />
        </div>
      );
    });

    if (productsRednered === 0)
      return <h5>Šajā kategorijā vēl nav nevienas preces</h5>;
    return redneredOutput;
  }

  async populateProductCategories() {
    const response = await this.getProductCategories();
    this.setState({ productCategories: response });
  }

  getProductCategories() {
    const url = `${API_URL}/api/product_categories/`;
    return new Promise((resolve, reject) => {
      fetch(url).then((response) => {
        resolve(response.json());
      });
    });
  }

  handleFilterChange = (categoryTitle) => {
    let categoryToSet =
      this.state.categoryFilter !== categoryTitle ? categoryTitle : null;
    this.setState({ categoryFilter: categoryToSet });
  };

  render() {
    return (
      <div className="main-content">
        <div className="product-catalogue">
          <div className="product-categories-list">
            <ProductCategories
              categories={this.state.productCategories}
              onFilterChange={this.handleFilterChange}
            />
          </div>
          <div className="product-list">
            <h1 className="uppercase">{this.state.categoryFilter}</h1>
            {this.renderProductList()}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCatalogue;
