import React, { useEffect } from "react";
import { Form, Col, Button } from "react-bootstrap";
import {postAuthRequest} from "../Authentication"

export default function Login() {
  useEffect(() => {

  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const formData = new FormData(document.getElementById('login-form'))
    const formDataObj = Object.fromEntries(formData.entries());
    const authResponse = await postAuthRequest(formDataObj)

    if (!authResponse.token) return alert('Unable to login')

    alert(authResponse.token)
    localStorage.setItem('authernticated_user_token', authResponse.token)
  };

  return (
    <div className="main-content">

        <div className="login-form-div">
        <Form id="login-form" onSubmit={handleSubmit}>

        <Form.Group as={Col} md="6" controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Username"
                name="username"
              />
            </Form.Group>            


            <Form.Group as={Col} md="6" controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control
                required
                type="password"
                placeholder="Password"
                name="password"
              />
            </Form.Group>    

            <Button type="submit">Login</Button>
        </Form>
        </div> 
      
    </div>
  );
}
