import React from "react";
import { fetchPost } from "../Fetch.js";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import SchedulerAlert from "./SchedulerAlert";

import CssBaseline from "@mui/material/CssBaseline";

var moment = require("moment-timezone");

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

Date.prototype.addHours = function (h) {
  const prevValue = Object.assign(this);
  prevValue.setTime(prevValue.getTime() + h * 60 * 60 * 1000);
  return prevValue;
};

const dtToRigaTimezone = (dt_str) => {
  let dt = moment(dt_str).toDate();
  /*
  const offset = dt.getTimezoneOffset();
  const minutesToAdd = offset + 180;
  dt = moment(dt).add(minutesToAdd, "m").toDate();
  */
  return dt;
};

const dtRigaNormalize = (dt_str) => {
  return moment(dt_str).utc(true).toDate();
};

const dtFormat = (now) => {
  if (!now) return "";

  const hoursRaw = now.getUTCHours();
  const hours = hoursRaw.toString().length === 2 ? hoursRaw : "0" + hoursRaw;

  const minutesRaw = now.getUTCMinutes();
  const minutes =
    minutesRaw.toString().lengt === 2 ? minutesRaw : "0" + minutesRaw;

  return `${now.getUTCFullYear().toString()}-${(
    now.getUTCMonth() + 1
  ).toString()}-${now.getUTCDate()} ${hours}:${minutes}`;
};

export default class CustomEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      start: dtToRigaTimezone(this.props.scheduler.state.start.value),
      end: dtToRigaTimezone(this.props.scheduler.state.start.value.addHours(2)),
      hoursSelected: 0,
      name: "",
      studio: this.props.studioSelected,
      rehearsalTypes: this.props.rehearsalTypes,
      rehearsal_type: this.props.rehearsalTypes.length
        ? this.props.rehearsalTypes[0].id
        : null,
      priceCalculated: null,
      minTime: dayjs("2018-01-01T08:00"),
      maxTimeStart: dayjs("2018-01-01T22:00"),
      maxTimeEnd: dayjs("2018-01-01T23:00"),
      priceCalculationError: false,
      overlapping: false,
      additional_services_selected: new Set(),
    };
  }

  componentDidMount() {
    if (this.state.start < new Date()) {
      this.props.earlierThanNowClicked();
      return this.props.scheduler.close();
    }
    const startValue = new Date(this.state.start.getTime());
    const hoursToAdd = startValue.getHours() === 22 ? 1 : 2;
    this.setState({ end: startValue.addHours(hoursToAdd) }, () =>
      this.getPriceCalculated()
    );
  }

  getPriceCalculated = async () => {
    const payload = { ...this.state };
    payload.start = dtRigaNormalize(payload.start);
    payload.end = dtRigaNormalize(payload.end);
    payload.additional_services_selected = [
      ...payload.additional_services_selected,
    ];
    const url = `/api/reservation/calculate_price/`;

    const response = await fetchPost(payload, url);
    if (isNaN(response)) {
      this.setState({ priceCalculationError: true });
      if (response.hasOwnProperty("overlapping")) {
        this.setState({ overlapping: response });
      }
    } else {
      this.setState({ overlapping: false, priceCalculationError: false });
      this.setState({ priceCalculated: response });
      this.setState({
        hoursSelected: Math.abs(this.state.start - this.state.end) / 36e5,
      });
    }
  };

  postReservationRequest = async () => {
    const payload = { ...this.state };
    payload.start = dtRigaNormalize(payload.start);
    payload.end = dtRigaNormalize(payload.end);
    payload.additional_services_selected = [
      ...payload.additional_services_selected,
    ];
    const url = `/api/reservation/request/`;

    const response = await fetchPost(payload, url);
    window.location.href = `/product_purchase/${response}`;
  };

  startChange = (newValue) => {
    let hoursToAdd = newValue.getHours() === 22 ? 1 : 2;
    let newEndValue = new Date(newValue).addHours(hoursToAdd);
    this.setState({ start: newValue, end: newEndValue }, () =>
      this.getPriceCalculated()
    );
  };

  endChange = (newValue) => {
    newValue.setDate(this.state.start.getDate());
    this.setState({ end: newValue }, () => this.getPriceCalculated());
  };

  endAddHours = (hoursToAdd) => {
    var startValue = new Date(this.state.start.getTime());
    this.setState({ end: startValue.addHours(hoursToAdd) }, () =>
      this.getPriceCalculated()
    );
  };

  handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.postReservationRequest();
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleChangeadditionalServices = (evt) => {
    let newSet = new Set(this.state.additional_services_selected);
    if (evt.target.checked) {
      newSet.add(evt.target.id);
    } else {
      newSet.delete(evt.target.id);
    }
    this.setState({ additional_services_selected: newSet }, () =>
      this.getPriceCalculated()
    );
  };

  renderRehearsalTypeOptions = () => {
    if (this.state.rehearsalTypes.length === 0) return;

    const redneredOutput = this.state.rehearsalTypes.map((rehearsalType) => {
      return (
        <option
          key={"rehearsal-type-" + rehearsalType.id}
          value={rehearsalType.id}
        >
          {rehearsalType.title}
        </option>
      );
    });

    return (
      <Form.Select
        aria-label="Rehearsal type"
        onChange={(event) => {
          this.setState({ rehearsal_type: event.target.value }, () =>
            this.getPriceCalculated()
          );
        }}
      >
        {redneredOutput}
      </Form.Select>
    );
  };

  render() {
    let submitBtn = <Button type="submit">Apstiprināt un maksāt</Button>;
    let priceCalculationAlert = <></>;
    if (this.state.overlapping) {
      const overlappingText = `Pārklājas ar rezervāciju ${moment(
        this.state.overlapping.from
      ).format("HH:mm")} - ${moment(this.state.overlapping.till).format(
        "HH:mm"
      )}`;
      priceCalculationAlert = (
        <SchedulerAlert text={overlappingText} variant="danger" show={true} />
      );
      submitBtn = <></>;
    }

    if (this.state.priceCalculationError) {
      submitBtn = <></>;
      priceCalculationAlert = (
        <SchedulerAlert
          text="Ar atpakaļejošu datumu un laiku rezervēt nav iespējams"
          variant="danger"
          show={true}
        />
      );
    }

    let additionalServices = <></>;
    if (this.props.additionalServices) {
      additionalServices = (
        <div className="additionalServicesDiv">
          <h6>Papildu pakalpojumi:</h6>
          {this.props.additionalServices.map((option) => (
            <Form.Check
              key={option.id}
              type="checkbox"
              id={option.id}
              label={`${option.title} (${option.price.toFixed(2)} EUR)`}
              onChange={this.handleChangeadditionalServices}
            />
          ))}
        </div>
      );
    }

    let calculatedPriceDiv = <></>;
    if (this.state.priceCalculated && !this.state.priceCalculationError) {
      calculatedPriceDiv = (
        <div>
          <p>
            Aprēķinātā cena: <b>{this.state.priceCalculated.toFixed(2)} EUR</b>
          </p>
        </div>
      );
    }

    const date = (
      <div className="inlineDiv">
        {dayjs(this.state.start).get("year")}-
        {dayjs(this.state.start).get("month") + 1}-
        {dayjs(this.state.start).get("date")}
      </div>
    );

    return (
      <div className="schedulerForm">
        <Form onSubmit={this.handleSubmit}>
          <div>
            <ThemeProvider theme={darkTheme}>
              <CssBaseline />
              <div style={{ padding: "1rem" }}>
                <h4>Studijas rezervācija {date}</h4>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <TimePicker
                    value={this.state.start}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                    label="No"
                    onChange={(newValue) => {
                      this.startChange(newValue.toDate());
                    }}
                    ampm={false}
                    minutesStep={30}
                    minTime={this.state.minTime}
                    maxTime={this.state.maxTimeStart}
                  />

                  <TimePicker
                    value={this.state.end}
                    renderInput={(props) => (
                      <TextField
                        {...props}
                        onKeyDown={(e) => e.preventDefault()}
                      />
                    )}
                    label="Līdz"
                    onChange={(newValue) => {
                      this.endChange(newValue.toDate());
                    }}
                    ampm={false}
                    minutesStep={30}
                    minTime={this.state.minTime}
                    maxTime={this.state.maxTimeEnd}
                  />
                </LocalizationProvider>
              </div>
            </ThemeProvider>

            <InputGroup className="mb-3">
              <Form.Control
                required
                placeholder="Vārds/grupa"
                aria-label="Vārds/grupa"
                aria-describedby="basic-addon1"
                onChange={(newValue) => {
                  this.handleNameChange(newValue);
                }}
              />

              {this.renderRehearsalTypeOptions()}
            </InputGroup>

            {additionalServices}
            {calculatedPriceDiv}
            {priceCalculationAlert}

            <div className="schedulerFormButtons">
              <div>{submitBtn}</div>
              <div className="schedulerFormButtonsClose">
                <Button onClick={this.props.scheduler.close}>Aizvērt</Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}
