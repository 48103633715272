import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";
import SwedbankLogo from "../../img/card-brands/swedbank_logo.png";
import { API_URL } from "../..";
import { fetchPost } from "../../Fetch.js"


export default function ProductPurchase() {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [formData, setFormData] = useState(false);

  const handleClose = () => setShow(false);
  const showModalConfirmation = () => setShow(true);

  const getUser = () => {
    return null
  } 

  const submitPuchaseRequest = async () => {
    const payload = {...formData}
    payload.price = state.price
    payload.currency = state.currency
    payload.product_id = state.pk
    payload.user = getUser()
    const url = '/api/payment'
    const redirect_url = await fetchPost(payload, url)
    window.location.href = redirect_url;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    
    
    const formData = new FormData(document.getElementById("form-purchase"));
    const formDataObj = Object.fromEntries(formData.entries());
    const paymentRadios = document.getElementsByName("paymentMethodRadios");
    paymentRadios.forEach((e) => {
      if (e.checked)
        formDataObj.internetBank = e.id.replace("paymentMethod", "");
    });
    setFormData(formDataObj);
    
    if (form.checkValidity()) return showModalConfirmation()
    
    setValidated(true);
  };

  const { id } = useParams();
  const [state, setState] = useState([]);

  const agreementText =
    "Apliecinu, ka piekrītu visiem Noteikumiem un nosacījumiem un Preces atgriešanas un naudas atmaksas nosacījumiem";

  useEffect(() => {
    if (state.length !== 0) return;
    fetch(`${API_URL}/api/product/${id}/`)
      .then((res) => res.json())
      .then((data) => setState(data[0]));
  });

  return (
    <div className="main-content">
      <div className="product-purchase-header">
        <h2>Pakalpojumu apmaksa:</h2>
        <p>Pakalpojumu: {state.title}</p>
        <p>
          Summa apmaksai: {state.price} {state.currency}
        </p>
      </div>

      <div className="product-purchase-form">
        <h3>Jūsu dati:</h3>

        <Form
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
          id="form-purchase"
        >
          <Row className="mb-3">
            <Form.Group as={Col} md="6" controlId="name">
              <Form.Label>Vards</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Vards"
                name="name"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="surname">
              <Form.Label>Uzvards</Form.Label>
              <Form.Control
                required
                type="text"
                placeholder="Uzvards"
                name="surname"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="email">
              <Form.Label>E-pasts</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="E-pasts"
                  name="email"
                  aria-describedby="inputGroupPrepend"
                  required
                />
              </InputGroup>
            </Form.Group>

            <Form.Group as={Col} md="6" controlId="phone">
              <Form.Label>Talrunis</Form.Label>
              <InputGroup hasValidation>
                <InputGroup.Text id="inputGroupPrepend">☎</InputGroup.Text>
                <Form.Control
                  type="number"
                  placeholder="Talrunis"
                  name="phone"
                  aria-describedby="inputGroupPrepend"
                  required
                />
              </InputGroup>
            </Form.Group>

            <fieldset className="payment-method-radios">
              <Form.Group as={Row} md="6" controlId="payment-method">
                <InputGroup hasValidation>
                  <Form.Label as="legend" column sm={2}>
                    Izvēlieties apmaksas veidu:
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Check
                      type="radio"
                      label="Swedbank"
                      name="paymentMethodRadios"
                      id="paymentMethodSwedbank"
                      required
                    />
                    <Form.Check
                      type="radio"
                      label="Luminor"
                      name="paymentMethodRadios"
                      id="paymentMethodLuminor"
                      required
                    ></Form.Check>
                    <Form.Check
                      type="radio"
                      label="Citadele"
                      name="paymentMethodRadios"
                      id="paymentMethodCitadele"
                      required
                    />
                    <Form.Check
                      type="radio"
                      label="SEB"
                      name="paymentMethodRadios"
                      id="paymentMethodSeb"
                      required
                    />
                  </Col>
                </InputGroup>
              </Form.Group>
            </fieldset>
          </Row>

          <Form.Group className="mb-3">
            <div className="swedbank-info">
              Droši pirkumi tiešsaistē ar Swedbank BankLink
              <img
                className="swedbank-logo"
                src={SwedbankLogo}
                alt="Swedbank logo"
              />
            </div>

            <Form.Check
              required
              label={agreementText}
              feedback="Pirms iesniegšanas ir jāpiekrīt."
              feedbackType="invalid"
            />
          </Form.Group>
          <Button type="submit">Apmaksāt</Button>
        </Form>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Pasūtījuma apstiprināšana</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Vards, uzvards: {formData.name} {formData.surname}
          </p>
          <p>E-pasts: {formData.email}</p>
          <p>Talrunis: {formData.phone}</p>
          <p>Internet banks: {formData.internetBank}</p>
          <p>Produkts: {state.title}</p>
          <p>
            Summa apmaksai: {state.price} {state.currency}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Atcelt
          </Button>
          <Button variant="primary" onClick={submitPuchaseRequest}>
            Apstiprināt
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
