import React, { Component } from "react";
import { ChevronDoubleDown, ChevronDoubleUp } from "react-bootstrap-icons";

let chevronEl = <ChevronDoubleDown />;

export default class ProductCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      moreCategoriesShown: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (!props.categories) return;
    return {
      categories: props.categories,
    };
  }

  renderCategoriesList() {
    const categoriesCount = this.state.categories.length;

    const showMoreCategories = () => {
      const els = document.getElementsByClassName("additional-categories");
      Array.from(els).forEach((e) => e.classList.toggle("hidden-category"));
      this.setState({moreCategoriesShown: !this.state.moreCategoriesShown})
      chevronEl = this.state.moreCategoriesShown ? (
        <ChevronDoubleUp />
      ) : (
        <ChevronDoubleDown />
      );
      this.forceUpdate();
    };



    return this.state.categories.map((category, index) => {
      if (index <= 2) {
        return (
          <div className="product-category-selector" key={category.title}>
            <button
              className="product-category-btn"
              onClick={() => this.props.onFilterChange(category.title)}
            >
              {category.title}
            </button>
          </div>
        );
      } else if (index > 2 && index !== categoriesCount - 1) {
        return (
          <div
            className="product-category-selector additional-categories hidden-category"
            key={category.title}
          >
            <button
              className="product-category-btn"
              onClick={() => this.props.onFilterChange(category.title)}
            >
              {category.title}
            </button>
          </div>
        );
      } else {
        return (
          <div key="final-category">
            <div
              className="product-category-selector additional-categories hidden-category"
              key={category.title}
            >
              <button
                className="product-category-btn"
                onClick={() => this.props.onFilterChange(category.title)}
              >
                {category.title}
              </button>
            </div>

            <div
              className="product-category-selector"
              key="more-categories-btn"
            >
              <button
                className="product-category-btn more-categories-btn"
                onClick={showMoreCategories}
              >
                {chevronEl}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  render() {
    return <div>{this.renderCategoriesList()}</div>;
  }
}
