import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {fetchGet} from "../Fetch.js";

export default class Branches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            displayAllStudios: true, // show/hide branch selector
            branches: [],
            studios: [],
            studioCount: 0,
            selectedBranch: null,
            selectedStudio: null,
        };
    }

    async componentWillMount() {
        this.populateBranchesStudios();
    }

    async getBranchesStudios() {
        return fetchGet(`/api/branches/`);
    }

    setStudiosAll() {
        let studios = [];
        this.state.branches.forEach((branch) => {
            branch.studio.forEach((studio) => {
                console.log(studio);
                studios.push(studio);
            });
        });
        this.setState({studios: studios});
    }

    setStudioCount() {
        let studioCount = 0;
        this.state.branches.forEach((branch) => studioCount += branch.studio.length);
        this.setState({studioCount: studioCount});
    }

    async populateBranchesStudios() {
        if (this.state.branches.length > 0) return;
        const res = await this.getBranchesStudios();
        if (!res || res.length === 0) return;
        this.setState({branches: res});
        if (this.state.displayAllStudios) this.setStudiosAll()
        this.selectDefaultStudio()
        this.setStudioCount()
    }


    selectDefaultStudio() {
        this.state.branches.forEach(branch => {
            branch.studio.forEach(studio => {
                if (studio.default_studio) {
                    this.selectBranch(branch)
                    this.selectStudio(studio)
                }
            })
        })
    }

    selectBranch(branch, event) {
        this.setState({selectedBranch: branch});
    }

    selectStudio(studio, event) {
        this.setState({selectedStudio: studio});
        this.props.studioSetter(studio.id);
    }

    getButtonClass(isTrue) {
        const baseClass = "schedulerButton"
        return isTrue ? baseClass + " selected" : baseClass
    }

    renderBranches() {
        return this.state.branches.map((branch) => {
            const key = `branch_${branch.pk}`;
            const className = this.getButtonClass(this.state.selectedBranch === branch)
            return (
                <Button key={key} className={className} onClick={(e) => this.selectBranch(branch, e)}>
                    {branch.title}
                </Button>
            );
        });
    }

    getStudiosForRender() {
        if (this.state.displayAllStudios) return this.state.studios;
        if (!this.state.selectedBranch) return [];
        return this.state.selectedBranch.studio;
    }

    renderStudios() {
        return this.getStudiosForRender().map((studio) => {
            const key = `studio_${studio.id}`
            const className = this.getButtonClass(this.state.selectedStudio === studio)
            return (
                <Button key={key} className={className} onClick={(e) => this.selectStudio(studio, e)}>
                    {studio.title}
                </Button>
            );
        });
    }

    renderHeader() {
        if (!this.state.selectedStudio) return;
        const selectedStudio = this.state.selectedStudio
        return `${selectedStudio.branch_title} (${selectedStudio.branch_address}) - ${selectedStudio.title}`
    }

    renderBranchSelector() {
        if (this.state.displayAllStudios) return null;
        return (<div className="studio-selector" id="branches">{this.renderBranches()}</div>);
    }

    render() {
        if (this.state.studioCount === 0) return null;
        return (
            <>
                {this.renderBranchSelector()}
                <div className="studio-selector" id="studios">{this.renderStudios()}</div>
                <h3 className="scheudlerHeader">{this.renderHeader()}</h3>
            </>
        );
    }
}
