import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import "./fonts/fontawesome-all.min.css";
import "./fonts/font-awesome.min.css";
import "./fonts/fontawesome5-overrides.min.css";

export default function App() {
  return (
    <>
      <Header />
      <Footer />
    </>
  );
}
