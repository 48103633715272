import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="footer-div">
        <div className="row ">
          <div className="col-md-4">
            <span className="copyright text-uppercase">
              Copyright Nemieriga Telpa 2021
            </span>
          </div>
          <div className="col-md-4">
            <ul className="list-inline social-buttons">
              <li className="list-inline-item">
                <a
                  href="https://www.facebook.com/NemierigaTelpa/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href="https://www.instagram.com/nt.studio.riga/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4">
            <div className="footer-contacts">
              <a href="/#contacts">Sazinies ar mums</a>
            </div>
          </div>

          <div className="col-md-4">
            <div className="footer-contacts">
              <Link to="/terms_and_conditions">NOTEIKUMI</Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
