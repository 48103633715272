import React, { useEffect } from "react";
import "./Header.css";
import { Link } from "react-router-dom";
import { ChevronDoubleDown } from "react-bootstrap-icons";

export default function Header() {
  useEffect(() => {
    navLinkClickNavbarToggle();
  });

  function navbarToggler() {
    const navbarMenu = document.getElementById("navbarMenu");
    if (!navbarMenu) return;
    navbarMenu.classList.toggle("collapse");
  }

  function navLinkClickNavbarToggle() {
    const navLinks = document.getElementsByClassName("nav-link");
    Array.from(navLinks).forEach((e) => {
      e.addEventListener("click", () => {
        navbarToggler();
      });
    });
  }

  return (
    <nav className="navbar navbar-expand-custom navbar-fixed-top navbar-dark flex-column align-items-stretch ">
      <div className="d-flex">
        <a href="/" className="navbar-brand mx-auto nt-logo">
          Nemierīga Telpa
        </a>
      </div>

      <button className="navbar-toggler" onClick={navbarToggler}>
        <ChevronDoubleDown />
      </button>

      <div
        className="collapse navbar-collapse w-100 sticky-top mx-auto"
        id="navbarMenu"
      >
        <ul className="navbar-nav mx-auto text-center">
          <li className="nav-item ">
            <Link to="/#about_us" className="nav-link">
              PAR MUMS
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/product_catalogue" className="nav-link">
              PAKALPOJUMI
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/scheduler" className="nav-link">
              BRĪVIE LAIKI
            </Link>
          </li>
          <li className="nav-item">
            <a href="/#gallery" className="nav-link">
              GALERIJA
            </a>
          </li>
          <li className="nav-item">
            <Link to="/terms_and_conditions" className="nav-link">
              NOTEIKUMI
            </Link>
          </li>
          <li className="nav-item">
            <a href="/#contacts" className="nav-link">
              KONTAKTI
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
